import { useEffect, useState } from "react";

export const Dispatcher = () => {
  const [device, setDevice] = useState(null);

  useEffect(() => {
    setDevice(getMobileOperatingSystem());
  }, []);

  // useEffect(() => {
  //   switch (device) {
  //     case "Android": {
  //       console.log("Android");
  //       alert("android");
  //       return;
  //     }
  //     case "iOS": {
  //       console.log("iOS");
  //       alert("ios");
  //       return;
  //     }
  //     default: {
  //       console.log("other", device);
  //       alert("other", device);
  //       return;
  //     }
  //   }
  // }, [device]);

  return <div>{device}</div>;
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};
