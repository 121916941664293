import React from "react";
import QRCode from "react-qr-code";

export const QrGenerator = () => {
  return (
    <div className="app">
      <QRCode value="https://qr.thecove.it/dispatcher" />
    </div>
  );
};
